// import { useIsMobile } from "../components/App";
import { users } from "../components/icons";
import { ToolButton } from "../components/ToolButton";
import { register } from "./register";

export const actionFollow = register({
  name: "follow",
  trackEvent: { category: "element" },
  perform(_, appState) {
    return {
      appState: {
        ...appState,
        followModeEnabled: !appState.followModeEnabled,
      },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ updateData, appState }) => {
    return (
      <ToolButton
        key={appState.followModeEnabled ? "Stop Sync" : "Sync"}
        type="button"
        icon={users}
        className={appState.followModeEnabled ? "dyte-sync" : ""}
        title={appState.followModeEnabled ? "Stop" : "Sync"}
        aria-label={appState.followModeEnabled ? "Stop" : "Sync"}
        showAriaLabel={true}
        onClick={updateData}
        data-testid="load-button"
      />
    );
  },
});
