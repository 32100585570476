import { ExcalidrawElement } from "../../element/types";
import { AppState } from "../../types";
import {
  clearAppStateForLocalStorage,
  getDefaultAppState,
} from "../../appState";
import { clearElementsForLocalStorage } from "../../element";
import { STORAGE_KEYS } from "../app_constants";
import { ImportedDataState } from "../../data/types";

export const saveUsernameToLocalStorage = (username: string) => {
  try {
    // localStorage.setItem(
    //   STORAGE_KEYS.LOCAL_STORAGE_COLLAB,
    //   JSON.stringify({ username }),
    // );
  } catch (error: any) {
    // Unable to access window.localStorage
    console.error(error);
  }
};

export const importUsernameFromLocalStorage = (): string | null => {
  try {
    // const data = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_COLLAB);
    // if (data) {
    //   return JSON.parse(data).username;
    // }
  } catch (error: any) {
    // Unable to access localStorage
    console.error(error);
  }

  return null;
};

export const importFromLocalStorage = () => {
  const savedElements = null;
  const savedState = null;

  try {
    // savedElements = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
    // savedState = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_APP_STATE);
  } catch (error: any) {
    // Unable to access localStorage
    console.error(error);
  }

  let elements: ExcalidrawElement[] = [];
  if (savedElements) {
    try {
      elements = clearElementsForLocalStorage(JSON.parse(savedElements));
    } catch (error: any) {
      console.error(error);
      // Do nothing because elements array is already empty
    }
  }

  let appState = null;
  if (savedState) {
    try {
      appState = {
        ...getDefaultAppState(),
        ...clearAppStateForLocalStorage(
          JSON.parse(savedState) as Partial<AppState>,
        ),
      };
    } catch (error: any) {
      console.error(error);
      // Do nothing because appState is already null
    }
  }
  return { elements, appState };
};

export const getElementsStorageSize = () => {
  return 0;
  // try {
  //   const elements = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
  //   const elementsSize = elements?.length || 0;
  //   return elementsSize;
  // } catch (error: any) {
  //   console.error(error);
  //   return 0;
  // }
};

export const getTotalStorageSize = () => {
  return 0;
  // try {
  // const appState = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_APP_STATE);
  // const collab = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_COLLAB);
  // const library = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_LIBRARY);

  //   const appStateSize = appState?.length || 0;
  //   const collabSize = collab?.length || 0;
  //   const librarySize = library?.length || 0;

  //   return appStateSize + collabSize + librarySize + getElementsStorageSize();
  // } catch (error: any) {
  //   console.error(error);
  //   return 0;
  // }
};

export const getLibraryItemsFromStorage = () => {
  return [];
  // try {
  //   const libraryItems: ImportedDataState["libraryItems"] = JSON.parse(
  //     localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_LIBRARY) as string,
  //   );

  //   return libraryItems || [];
  // } catch (error) {
  //   console.error(error);
  //   return [];
  // }
};
